const langs = [
  {
    name: 'rest',
    title: 'Raw',
  },
  {
    name: 'dotnet',
    title: '.NET Client'
  },
  {
    name: 'js-snclient',
    title: 'JS Client'
  },
]

module.exports = langs;
